exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blogdetails-js": () => import("./../../../src/pages/blogdetails.js" /* webpackChunkName: "component---src-pages-blogdetails-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careersdetails-js": () => import("./../../../src/pages/careersdetails.js" /* webpackChunkName: "component---src-pages-careersdetails-js" */),
  "component---src-pages-casestudy-js": () => import("./../../../src/pages/casestudy.js" /* webpackChunkName: "component---src-pages-casestudy-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-ctrm-js": () => import("./../../../src/pages/ctrm.js" /* webpackChunkName: "component---src-pages-ctrm-js" */),
  "component---src-pages-gmail-connector-guide-js": () => import("./../../../src/pages/gmail-connector-guide.js" /* webpackChunkName: "component---src-pages-gmail-connector-guide-js" */),
  "component---src-pages-gmail-connector-js": () => import("./../../../src/pages/gmailConnector.js" /* webpackChunkName: "component---src-pages-gmail-connector-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-meta-tag-js": () => import("./../../../src/pages/metaTag.js" /* webpackChunkName: "component---src-pages-meta-tag-js" */),
  "component---src-pages-monday-com-js": () => import("./../../../src/pages/monday-com.js" /* webpackChunkName: "component---src-pages-monday-com-js" */),
  "component---src-pages-msd-services-js": () => import("./../../../src/pages/msd-services.js" /* webpackChunkName: "component---src-pages-msd-services-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rpa-js": () => import("./../../../src/pages/rpa.js" /* webpackChunkName: "component---src-pages-rpa-js" */),
  "component---src-pages-solution-header-details-components-solution-details-info-js": () => import("./../../../src/pages/SolutionHeaderDetails/components/solutionDetailsInfo.js" /* webpackChunkName: "component---src-pages-solution-header-details-components-solution-details-info-js" */),
  "component---src-pages-solution-header-details-solution-details-js": () => import("./../../../src/pages/SolutionHeaderDetails/solutionDetails.js" /* webpackChunkName: "component---src-pages-solution-header-details-solution-details-js" */),
  "component---src-pages-solution-header-details-solution-header-js": () => import("./../../../src/pages/SolutionHeaderDetails/solutionHeader.js" /* webpackChunkName: "component---src-pages-solution-header-details-solution-header-js" */),
  "component---src-pages-solution-header-details-solution-number-js": () => import("./../../../src/pages/SolutionHeaderDetails/solutionNumber.js" /* webpackChunkName: "component---src-pages-solution-header-details-solution-number-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-studydetails-js": () => import("./../../../src/pages/studydetails.js" /* webpackChunkName: "component---src-pages-studydetails-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms_and_conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-of-services-connector-js": () => import("./../../../src/pages/terms-of-services-connector.js" /* webpackChunkName: "component---src-pages-terms-of-services-connector-js" */),
  "component---src-pages-terms-of-services-timerely-js": () => import("./../../../src/pages/terms-of-services-timerely.js" /* webpackChunkName: "component---src-pages-terms-of-services-timerely-js" */),
  "component---src-pages-timerely-guide-js": () => import("./../../../src/pages/timerely-guide.js" /* webpackChunkName: "component---src-pages-timerely-guide-js" */),
  "component---src-pages-timerelypage-js": () => import("./../../../src/pages/timerelypage.js" /* webpackChunkName: "component---src-pages-timerelypage-js" */)
}

