import React, { useContext } from "react";
import { Link } from "gatsby";
import ApprelyLogoIcon from "../assets/images/logo/apprely-logo.svg";
import HumburgerIcon from "../assets/images/Icons/humburger-icon.svg";

export default function Nav({ activeItem, setActiveItem, navComponents }) {
  const navComponentsSomeArray = navComponents?.slice(0,5);

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const handleLogoClick = (item) => {
    setActiveItem(item);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light ap__navbar">
        <div className="container-fluid">
          <Link
            to="/"
            onClick={() => handleLogoClick("/")}
            className="navbar-brand"
          >
            <img
              src={ApprelyLogoIcon}
              alt={ApprelyLogoIcon}
              className="logo"
              height="100"
            />
          </Link>
          <button
            style={{ border: 0 }}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <span style={{
              color: 'white',
              fontFamily: 'var(--unnamed-font-MontserratLightBold)',
              fontWeight: '700',
              fontSize: '14px',
              marginRight: '10px',
            }}>Menu</span> */}
            <img src={HumburgerIcon} alt={HumburgerIcon} />
            {/* <span className="navbar-toggler-icon"></span> */}
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto">
              {(navComponentsSomeArray || []).map((item) => {
                return (
                  <>
                    {item.label !== "Contact Us" ? (
                      <li
                        className="nav-item"
                        onClick={() => handleItemClick(`${item.label}`)}
                      >
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to={`${item.route}`}
                        >
                          <span
                            className={
                              activeItem === `${item.label}`
                                ? "nav-item active"
                                : "nav-item"
                            }
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            {item.label}
                          </span>
                        </Link>
                      </li>
                    ) : (
                      <>
                        <li className="dropdown">
                          <a
                            className={
                              activeItem === `/integration` ||
                              activeItem === `/rpa`
                                ? "nav-link dropdown-toggle"
                                : "nav-link dropdown-toggle"
                            }
                            href="#"
                            role="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Capability
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                            style={{
                              listStyleImage: "none",
                              listStyleType: "none",
                            }}
                          >
                            <div className="dropwdown-top-angle__arrow"></div>
                            <Link
                              className="dropdown-item"
                              aria-current="page"
                              to={"/integration"}
                              onClick={() => handleItemClick(`/integration`)}
                            >
                              <li
                                onClick={() => handleItemClick(`/integration`)}
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                              >
                                <span
                                  className={
                                    activeItem === `/integration` && "active"
                                  }
                                >
                                  Integration
                                </span>
                              </li>
                            </Link>
                            <Link
                              className="dropdown-item"
                              aria-current="page"
                              to={"/rpa"}
                              onClick={() => handleItemClick(`/rpa`)}
                            >
                              <li
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                              >
                                <span
                                  className={activeItem === `/rpa` && "active"}
                                >
                                  RPA Bot
                                </span>
                              </li>
                            </Link>
                            <Link
                              className="dropdown-item"
                              aria-current="page"
                              to={"/ctrm"}
                              onClick={() => handleItemClick(`/ctrm`)}
                            >
                              <li
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                              >
                                <span
                                  className={activeItem === `/ctrm` && "active"}
                                >
                                  CTRM
                                </span>
                              </li>
                            </Link>
                            <Link
                              className="dropdown-item"
                              aria-current="page"
                              to={"/monday-com"}
                              onClick={() => handleItemClick(`/monday-com`)}
                            >
                              <li
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                              >
                                <span
                                  className={
                                    activeItem === `/monday-com` && "active"
                                  }
                                >
                                  monday.com
                                </span>
                              </li>
                            </Link>
                            <Link
                              className="dropdown-item"
                              aria-current="page"
                              to={"/msd-services"}
                              onClick={() => handleItemClick(`/msd-services`)}
                            >
                              <li
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                              >
                                <span
                                  className={
                                    activeItem === `/msd-services` && "active"
                                  }
                                >
                                  MSD Services
                                </span>
                              </li>
                            </Link>
                            {/* <Link 
                              className="dropdown-item" 
                              aria-current="page" 
                              to={'/marketing-page'}
                              onClick={() => handleItemClick(`/marketing-page`)}
                              >
                              <li 
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                             
                                <span
                                  className={activeItem === `/marketing-page` && "active"} 
                                >monday.com</span>
                              </li>
                            </Link> */}
                            {/* <Link 
                              className="dropdown-item" 
                              aria-current="page" 
                              to={'/gmailConnector'}
                              onClick={() => handleItemClick(`/gmail_connector`)}
                              >
                              <li 
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show">
                             
                                <span
                                  className={activeItem === `/gmail_connector` && "active"} 
                                >Gmail Connector</span>
                              </li>
                            </Link> */}
                          </ul>
                        </li>
                        <Link
                          to="/contactus"
                          onClick={() => handleItemClick(`${item.label}`)}
                          className="btn btn__primary-bg btn--w-155 ap-fw__700"
                        >
                          <span
                            className="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Contact us
                          </span>
                        </Link>
                      </>
                    )}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
